import "./settings.css";
import { PermIdentity } from "@material-ui/icons";
import { Sidebar } from "../../component/sidebar/Sidebar";
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

export const Settings = () => {
  const selectedUser = useSelector((state) => state.user.users)
  const location = useLocation().pathname.split("/")[2].toString()
  
  //find particular user
  const selected = selectedUser.find(user => user._id.toString() === location)

  return (
    <div className="container">
      <Sidebar />
      <div className="settings">
        <div className="userSettingContainer">
          <h1 className="userTitle">Edit User</h1>
        </div>
        <div className="userContainer">
          <div className="userShow">
            <div className="userShowTop">
              <div className="userShowTopTitle">
                <span className="userShowUsername">{selected?.fname + ' ' + selected?.lname}</span>
                <span className="userShowTitle">{selected?.role}</span>
              </div>
            </div>
            <div className="userShowBtm">
              <span className="userShowTitle">Account Details</span>
              <div className="userShowInfo">
                <PermIdentity className="userIcon" />
                <span className="userShowInfoTitle">ID#: { selected._id}</span>
              </div>
            </div>
          </div>
          <div className="userUpdate">
            <span className="userUpateTitle">Edit</span>
            <form className="userUpdateForm">
              <div className="userUpdateLeft">
                <div className="userUpdateItem">
                  <label>Role Id </label>
                  <input
                    type="text"
                    className="userUpdateInput"
                    placeholder={ selected?.roleId}
                    contentEditable= "false"
                  />
                </div>

                <div className="userUpdateItem">
                  <label>User Name</label>
                  <input
                    type="text"
                    className="userUpdateInput"
                    placeholder="Username"
                    defaultValue={selected?.fname}
                    contentEditable="true"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Role</label>
                  <input
                    type="text"
                    className="userUpdateInput"
                    placeholder={selected?.role}
                  />
                </div>
              </div>
              <div className="userUpdateRight">
                <div className="userUpdateItem">
                  <label>Signature</label>
                  <input
                    type="file"
                    className="userUpdateInput"
                    placeholder="User Id"
                  />
                </div>
                <button className="userUpdateBtn">Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
