import React from "react";
import "./sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import { Done, ExitToApp, History, Home, Settings } from "@material-ui/icons";

export const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    alert("Logout successful");
    setTimeout(() => {
      navigate("/login");
    }, 2000);
  };

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <div
            className="meu-home"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <h3 className="sidebarTitle">Admin |</h3>

            <Link to="/" className="links">
              <li
                className=" active"
                style={{
                  textDecoration: "underline",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <Home style={{ fontSize: "12px" }} />
                <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                  ALL MEMO
                </span>
              </li>
            </Link>
          </div>
          <ul className="sidebarList">
            <Link to="/users" className="links">
              <li className="sidebarItems">
                <Done className="sidebarIcon" />
                <span className="menu-item">USERS</span>
              </li>
            </Link>

            <Link to="/" className="links">
              <li className="sidebarItems">
                <History className="sidebarIcon" />
                <span className="menu-item">ALL MEMOS</span>
              </li>
            </Link>

            <Link to="/settings" className="links">
              <li className="sidebarItems">
                <Settings className="sidebarIcon" />
                <span className="menu-item">SETTINGS</span>
              </li>
            </Link>

            <li className="sidebarItems" onClick={handleLogout}>
              <ExitToApp className="sidebarIcon" />
              <span className="menu-item">LOGOUT</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
