import { Table } from "../../component/table/Table";
import "./home.css";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Sidebar } from "../../component/sidebar/Sidebar";

export const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="container">
      <Sidebar />
      <div className="home">
        <Table />
      </div>
    </div>
  );
};
