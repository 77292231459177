export const style = {
  position: "absolute",
  top: "40%",
  left: "33%",
  transform: "translate(-50%, -50%)",
  width: "150mm",
  minHeight: "150mm",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  background: "white",
  boxShadow: 3,
  p: 5,
};
