import { Table } from "../../component/table/Table"
import "./archives.css"

export const Archives = () => {
  return (
    <div className="archives">
      <Table />
    </div>
  )
}
