import Box from "@mui/material/Box";
import "./memolist.css";
import "../../App.css";
import ReactHtmlParser from "react-html-parser";
import { style } from "./boxstyle";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Sidebar } from "../../component/sidebar/Sidebar";

export const MemoList = () => {
  const [data, setData] = useState([]);
  const [comment, setComment] = useState([]);
  const location = useLocation().pathname.split("/")[2].toString();

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      navigate("/login");
    }
    fetchData();
    fetchComment();
  }, [navigate]);

  const TOKEN = "executivedirector";
  //fetch comments

  const fetchComment = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/comment/`);
      setComment(res.data);
    } catch (error) {}
  };

  //fecth data from api
  const fetchData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/memo/`, {
        headers: {
          token: "Bearer" + TOKEN,
        },
      });
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  let c = comment.filter((result) => result.memoId === location);
  let memolist = data.find((result) => result.id === location);

  return (
    <div className="container">
      <Sidebar />
      <div className="memolist">
        <div className="memolist-container">
          <div className="memo-page">
            <div className="memo-details">
              <Box sx={style}>
                <Typography variant="h6" component="h6">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <p style={{ fontSize: "12px" }}>
                        MEMO ID: {memolist?.id}
                      </p>
                      <div>
                        <span
                          style={{
                            color: "teal",
                            fontSize: "12px",
                            paddingRight: "5px",
                          }}
                        >
                          Status: {memolist?.status}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "green",
                        fontFamily: "'Ibarra Real Nova', serif",
                      }}
                    >
                      <img
                        src={
                          "http://res.cloudinary.com/lexreal1/image/upload/v1666785970/upload/gcd2hfmso4vvgtcl6ra4.png"
                        }
                        alt="logo"
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="header">
                    <p>TO : {memolist?.to}</p>
                    <p>FROM : {memolist?.from}</p>
                    <p>Cc : {memolist?.copy}</p>
                    <p>THROUGH : {memolist?.through}</p>
                    <p className="date">
                      DATE : {memolist?.date.split("T")[0]}
                    </p>
                    <p style={{ marginTop: "10px" }} className="pg">
                      {" "}
                      SUBJECT :{" "}
                      <span style={{ textDecoration: "underline" }}>
                        {memolist?.subject}
                      </span>
                    </p>
                  </div>
                </Typography>
                <Typography className="content">
                  <div className="content-details">
                    <div>
                      {ReactHtmlParser(
                        `<p style={{fontSize:"10px"}}>${memolist?.content}</p>`
                      )}
                    </div>

                    {/* end of content */}
                    {memolist?.files[0].file_name !== "" && (
                      <div
                        className="content-attachement"
                        style={{ fontSize: "12px", marginBottom: "10px" }}
                      >
                        {" "}
                        Attachments:
                        {memolist?.files.map((file, i) => (
                          <div key={i} style={{ marginTop: "4px" }}>
                            <span
                              style={{
                                fontSize: "15px",
                                textTransform: "uppercase",
                              }}
                            >
                              {file.file_name}:
                            </span>
                            <button
                              className="doc_atc"
                              onClick={() => window.open(file.url)}
                            >
                              View Document
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div
                    className="sign"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <img
                      src={memolist?.signature}
                      alt="signature"
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "contain",
                      }}
                    />
                    <p style={{ fontSize: "12px" }}>({memolist?.sender})</p>
                  </div>
                </Typography>
                <button className="download-btn">Download PDF</button>
              </Box>
            </div>
            {/* comments */}
            <div className="comments">
              <span
                style={{ borderBottom: "2px solid purple", color: "GrayText" }}
              >
                Comments
              </span>

              {c.map((comment, i) => (
                <div key={i} className="comment-details">
                  <div className="comment-top">
                    <span className="cid">ID: {comment?.memoId}</span>
                    <span>|</span>
                    <span className="cdate">
                      {comment?.createdAt.split("T")[0]}
                    </span>
                  </div>
                  <div>
                    <span style={{color: "#BD910E"}}>@{comment?.receiver}</span>
                    <span> {comment?.comment.message}</span>
                  </div>
                  
                  <p>FROM: {comment?.comment.from}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
