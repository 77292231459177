import axios from "axios";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import "./signatories.css";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { Sidebar } from "../../component/sidebar/Sidebar";
import { getUSers } from "../../redux/userRedux";

export const Users = () => {
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUsers = async () => {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/`, {
        headers: {
          token: "Bearer " + localStorage.getItem("token"),
        },
      });
      setUsers(res.data);
     dispatch(getUSers(res.data))
    };
    getUsers();
  }, [dispatch]);
  
  const columns = [
    { field: "_id", headerName: "ID", width: 150 },
    { field: "createdAt", headerName: "DATE", width: 110, type: "date" },
    { field: "fname", headerName: "First Name", width: 150, type: "text" },
    { field: "lname", headerName: "Last Name", width: 150, type: "text" },
    { field: "roleId", headerName: "ROLE ID", width: 80, type: "text" },
    { field: "role", headerName: "ROLE", width: 250, type: "text" },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/edit/" + params.row._id}>
              <button style={{cursor:'pointer'}}>VIEW</button>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <div className="container">
      <Sidebar />
      <div style={{ height: "100vh", width: "100%", flex: 4 }}>
        <DataGrid
          rows={users}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[10]}
          checkboxSelection
          disableSelectionOnClick
          getRowId={(row) => row._id}
        />
      </div>
    </div>
  );
};
