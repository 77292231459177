import "./App.css";
import { Topbar } from "./component/topbar/Topbar";
import { Home } from "./pages/home/Home";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Archives } from "./pages/archives/Archives";
import { Settings } from "./pages/settings/Settings";
import { MemoList } from "./pages/memoList/MemoList";
import { NewUser } from "./pages/newUser/NewUser";
import { Users } from "./pages/signatories/Users";
import { Login } from "./pages/login/Login";
import { useEffect, useState } from "react";

function App() {
  const [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    const user = localStorage.getItem("user");
    setCurrentUser(user?.currentUser.accessToken);
  }, []);

  if (currentUser === null) {
    return <Navigate to="/login" />;
  }

  return (
    <BrowserRouter>
      <Topbar />
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="/" element={<Archives />} />
          <Route path="login" element={<Login />} />
          <Route path="users" element={<Users />} />
          <Route path="edit/:userId" element={<Settings />} />
          <Route path="settings" element={<NewUser />} />
          <Route path="memo/:memoId" element={<MemoList />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
