import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./login.css";
import { toast, ToastContainer } from "react-toastify";

const notify = (message) => toast.error(message);
const success = (message) => toast.success(message);


export const Login = () => {
  const [fname, setFname] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== "" || fname !== "") {
      success("Please wait....")
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/auth/login`,
          {
            fname,
            password,
          }
        );
        const accessToken = await JSON.stringify(res.data.accessToken);
        localStorage.setItem("token", accessToken);
        if (accessToken) {
          setTimeout(() => {
            navigate("/");
            notify("Login successful redirecting to homepage")
          }, 2000);
        }
      } catch (error) {
        notify(error.response.data);
      }
      setFname("");
      setPassword("");
    } else {
      alert("login details incorrect");
    }
  };

  return (
    <div className="login-container">
      <div className="side-img">heoo</div>
      <form className="login">
        <div>
          <h2>SIGN IN</h2>
        </div>
        <input
          type="text"
          placeholder="Firstname in caps*"
          autoComplete="true"
          value={fname}
          onChange={(e) => setFname(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password *"
          autoComplete="false"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" className="loginBtn" onClick={handleSubmit}>
          LOGIN
        </button>
        <span
          style={{
            marginTop: "15px",
            fontSize: "12px",
            fontFamily: "cursive",
            textDecoration: "underline",
          }}
        >
          Contact I.T to reset your password if forgotten.
        </span>
      </form>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};
