import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import "./table.css";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

const columns = [
  { field: "id", headerName: "ID", width: 150 },
  { field: "date", headerName: "Date", width: 100, type:"date"},
  { field: "to", headerName: "Sent To", width: 200, type: "text" },
  { field: "from", headerName: "Set By", width: 200, type: "text" },
  {
    field: "subject",
    headerName: "Subject",
    sortable: false,
    width: 200,
  },
  { field: "status", headerName: "Status", width: 80, type: "text" },
  {
    field: "action",
    headerName: "Action",
    width: 100,
    renderCell: (params) => {
      return (
        <>
          <Link to={"/memo/"+params.row.id}>
            <button style={{cursor:'pointer'}} >VIEW</button>
          </Link>
        </>
      );
    },
  },
];

export const Table = () => {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    fetchData()
  },[])
  //fecth data from api
  const fetchData  = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/memo/`)
      setData(res.data)
    } catch (error) {
      console.log(error);
    }
  }
  //fetch comments
  
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
      sx={{
        boxShadow: 1,
        border: 1,
        fontSize: 12,
        fontFamily: "sans-serif",
        fontStyle: "normal",
        borderColor: "primary.light",
        "& .MuiDataGrid-cell:hover": {
          color: "primary.main",
        },
      }}
        rows={data}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[10]}
        checkboxSelection
        disableSelectionOnClick
        
      />
    </div>
  );
};
